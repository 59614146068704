import React from "react";
import { GatsbyLocation } from "local-types";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { useSiteMetadata } from "../hooks";

interface NotFoundPageProps {
  location: GatsbyLocation;
}

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = ({
  location,
}): React.ReactElement => {
  const { title: siteTitle } = useSiteMetadata();

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <h1>Not Found</h1>
      <p>The force is strong in this one...</p>
      <p>Just not strong enough to find what you were looking for.</p>
    </Layout>
  );
};

export default NotFoundPage;
